import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue')
   }
   ,
  
  {     
     path: '/partner',
    name: 'partner',
    component: () => import('@/views/partner.vue')
  },
    
  {     
    path: '/mynft',
   name: 'mynft',
   component: () => import('@/views/mynft.vue')
 },
  
  {
     
    path: '/qbdetail',
   name: 'qbdetail',
   component: () => import('@/views/qbdetail.vue')
 },
 {
     
  path: '/qbdetails',
 name: 'qbdetails',
 component: () => import('@/views/qbdetails.vue')
},

{
     
  path: '/Detail',
 name: 'Detail',
 component: () => import('@/views/Detail.vue')
},

 

 {     
  path: '/active',
 name: 'active',
 component: () => import('@/views/active.vue')
},
{     
  path: '/activere',
 name: 'activere',
 component: () => import('@/views/activere.vue')
},


{     
  path: '/investment',
 name: 'investment',
 component: () => import('@/views/investment.vue')
},

   
  

]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    //设置回到顶部
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router
