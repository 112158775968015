<template>
  <div class="hbg">
  <div class="home">
    <div class="top">
        <div class="navbtn fadeInRight" @click="ethConnect">
        <span>{{userWallet?userWallet :'Connect' |yingcang}}</span>
      </div>
      <!-- <div class="logo fadeInLeft">   
     <div class="slogo " > 
       <div class="slo">
       <img src="../assets/img/slogo.png" alt=""  class="img2" />
       </div>
       <span class="ttt">LOGO</span>
       </div>       
      </div> -->
      <div class="flexright">
      <div class="lang" @click="changer(dqyy)">{{ dqyy == "en-US" ? "English" : "中文"}}</div>
        <div class="icosn">
     <img src="../assets/img/menu.png" alt="" class="img" @click="menuopen" />      
     </div>
     </div>
    </div>

 <div class="content">
     <div class="actives fadeInDow">
        <!-- <img src="../assets/img/logo.png" alt=""  class="simg" />         -->
              <van-swipe
        class="my-swipe1"
        :autoplay="3000"
        :show-indicators="false"      >
        <van-swipe-item v-for="(item, index) in banner" :key="index">
          <img :src="dqyy =='en-US'?item.thumb_en: item.thumb" alt="" class="imgss" />
        </van-swipe-item>
      </van-swipe>  
          <!-- <div class="infos" >
          {{$t('m.hdxq')}}
        </div>      -->
      </div>

      <div class="card2 bg6 a">
        <span class="a1"></span>
        <span class="a2"></span>
        <span class="a3"></span>
        <span class="a4"></span>
          <div class="mytg">
            <div class="left">
            <img src="../assets/img/blogo.jpg" alt="" class="ico">
            <div class="tt">WJT{{ $t("m.dqbj") }}</div>
            </div>
            <div class="right" > 
              {{price}} USDT
                <!-- <img src="../assets/img/next.png" alt="" class="ico"> -->
            </div>
          </div>
      </div>

      <div class="card2 bg6 a">
        <span class="a1"></span>
        <span class="a2"></span>
        <span class="a3"></span>
        <span class="a4"></span>
          <div class="mytg">
            <div class="left">
            <img src="../assets/img/logo8.png" alt="" class="ico">
            <div class="tt">Aleo{{ $t("m.dqbj") }}</div>
            </div>
            <div class="right" > 
              {{0}} USDT
                <!-- <img src="../assets/img/next.png" alt="" class="ico"> -->
            </div>
          </div>
      </div>
      <div ref="eacht" class="eacht "></div>

      <div class="card5">
         <img src="../assets/img/logo.png" alt="" class="slogo">
        <div class="list">          
          <div class="item">
            <div class="tt"> {{$t('m.qyjf')}} </div>
             <div class="num">{{total?(150000000-total).toFixed(4):0}} </div>
          </div>
           <div class="item">
            <div class="tt"> {{$t('m.xhsl')}} </div>
             <div class="num">{{total||0}} </div>
          </div>
     
          </div>
      </div>
      
      <div class="card2 fadeInUp sbfg"  >
        <!-- <div class="imgroup">
          <img src="../assets/img/logo.png" alt="" class="img">
           <div class="title"> {{$t('m.bqhd')}} </div>
        </div> -->
        <div class="btn" @click="handleFiexdToolItem('index')">
        <div class="tnxt">
          {{$t('m.slzl')}}
        </div>
        <img src="../assets/img/logow.png" alt="" class="she"/>
        </div>
      </div>
      
      <div class="linebox">
      <div class="card3 bg6 a">  
        <span class="a1"></span>
        <span class="a2"></span>
        <span class="a3"></span>
        <span class="a4"></span>    
        <div class="list">         
          <div class="item">
            <div class="tt"> {{$t('m.jrsy')}} </div>
          </div>
          <div class="item">
                  <div class="num">{{userInfo.wjtuDayAmount||0}} USDT</div>     
                     <div class="nums">{{userInfo.aleoDayAmount||0}} Aleo</div>   
                     <div class="nums">{{userInfo.wjtDayAmount||0}} WJT</div>         
                </div>
          </div>
        </div>
        <div class="card3 bg6 a">
        <span class="a1"></span>
        <span class="a2"></span>
        <span class="a3"></span>
        <span class="a4"></span>
          <div class="list">           
          <div class="item">
            <div class="tt">{{$t('m.ljsy')}} </div>
          </div>
          <div class="item">
                  <div class="num">{{userInfo.wjtuTolalAmount||0}} USDT</div>     
                     <div class="nums">{{userInfo.aleoTotalAmount||0}} Aleo</div>   
                     <div class="nums">{{userInfo.wjtTotalAmount||0}} WJT</div>           
                </div>
          </div>
      </div>    
        
      </div>

      <div class="linebox">
      <div class="card3 bg6">
        <div class="list listline">
           <img src="../assets/img/next.png" alt="" class="she" @click="handleFiexdToolItem('qbdetail',1)">
          <div class="item">
            <img src="../assets/img/logo.png" alt="" class="ico">
            <div class="tt"> WJT</div>
             <div class="num">{{userInfo.wjtAmount||0}} (${{(userInfo.wjtAmount*price).toFixed(2)}})</div>
          </div>
          <!-- <div class="item">           
                <div class="btn" @click="lqselect(userInfo.jlAmount)">
              {{ $t("m.tixian") }}</div>
          </div> -->
          </div>
      </div>
      </div>

        <div class="linebox">
      <div class="card3 bg6">
        <div class="list listline">
           <img src="../assets/img/next.png" alt="" class="she" @click="handleFiexdToolItem('qbdetail',2)">
          <div class="item">
            <img src="../assets/img/logo8.png" alt="" class="ico">
            <div class="tt"> Aleo </div>
             <div class="num">{{userInfo.aleoAmount||0}} ($0.00)</div>
          </div>
          <!-- <div class="item">           
                <div class="btn" @click="lqselect(userInfo.fhAmount)">
              {{ $t("m.tixian") }}</div>
          </div> -->
          </div>
      </div>
      </div>
     
<div class="linebox">
      <div class="card3 bg6">
        <div class="list">         
          <div class="item">
            <!-- <img src="../assets/img/bm/i3.png" alt="" class="ico"> -->
            <div class="tt"> {{$t('m.grztzc')}}</div>
          </div>
          <div class="item">
            <div class="num">{{userInfo.p2||0}} CS</div>           
          </div>
          </div>
      </div>
      </div>

      <div class="linebox">
            <div class="card3 bg6">
              <div class="list">         
                <div class="item">
                  <!-- <img src="../assets/img/bm/i3.png" alt="" class="ico"> -->
                  <div class="tt">{{$t('m.ztztzc')}}</div>
                </div>
                <div class="item">
                  <div class="num">{{userInfo.r2||0}} CS</div>   
                      
                </div>
                </div>
            </div>
              <div class="card3 bg6">
                <div class="list">           
                <div class="item">
                  <!-- <img src="../assets/img/bm/i3.png" alt="" class="ico"> -->
                  <div class="tt">{{$t('m.sqztzc')}}</div>
                </div>
                <div class="item">
                  <div class="num">{{userInfo.t2||0}} CS</div>
                </div>
                </div>
            </div>     
              
            </div>

           
    

         

        <!-- <div class="card fadeInUp"  >     
        <div class="btn" @click="handleFiexdToolItem('index')">
        <div class="tnxt">
         {{$t('m.ystz')}}
        </div>
        <img src="../assets/img/tz.png" alt="" class="she"/>
        </div>
      </div> -->
      
     

 

            <div class="linebox">
            <div class="card3 bg6">
              <div class="list">         
                <div class="item">
                  <!-- <img src="../assets/img/qb.png" alt="" class="ico"> -->
                  <div class="tt">{{$t('m.grtred')}}</div>
                </div>
                <div class="item">
                  <div class="num">{{userInfo.p1||0}} USDT</div>           
                </div>
                </div>
            </div>              
              
            </div>

          

      <div class="linebox">
            <div class="card3 bg6">
              <div class="list">         
                <div class="item">
                  <!-- <img src="../assets/img/qb.png" alt="" class="ico"> -->
                  <div class="tt"> {{$t('m.zttred')}}</div>
                </div>
                <div class="item">
                  <div class="num">{{userInfo.r1||0}} USDT</div>           
                </div>
                </div>
            </div>
              <div class="card3 bg6">
                <div class="list">           
                <div class="item">
                  <!-- <img src="../assets/img/qb.png" alt="" class="ico"> -->
                  <div class="tt"> {{$t('m.sqtred')}}</div>
                </div>
                <div class="item">
                  <div class="num">{{userInfo.t1||0}} USDT</div>
                </div>
                </div>
            </div>    
           </div>


    <div class="card4">
      <div class="tips">{{$t('m.yqr')}}:  {{this.$store.getters.intID|yingcang2}}</div>
     <div class="tips">{{$t('m.yqinfo')}}</div>
      <div class="yqlink">
        <div class="ltxt">{{$t('m.yqlink')}}</div>
        <div class="mylink">
          <div class="address">{{ codeWallet }}</div>
          <button
            type="button"
            class="fuzhi"
            v-clipboard:copy="codeWallet"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <img src="../assets/img/fz.png" alt="" class="icon" />
          </button>
        </div>
      </div>
    </div>

      <div class="linebox">
      <div class="card3">       
           <div class="list">         
          <div class="item">
            <div class="tt"> {{ $t("m.fxrs") }}</div>
          </div>
          <div class="item">
            <div class="num">{{userInfo.recommendCount||0}} </div>           
          </div>
          </div>
           <div class="list">         
          <div class="item">
            <div class="tt"> {{ $t("m.sqrs") }}</div>
          </div>
          <div class="item">
            <div class="num">{{userInfo.teamNum||0}} </div>           
          </div>
          </div>
      </div>
      </div> 

   

      
    <div class="buttoms">Copyright © 2024 wujie. All rights reserved.</div>

      <!-- <div class="flexbottom fadeInUp" @click="handleFiexdToolItem('index')">
       <div class="txt"> Aleo</div>
       <div class="txt">Aleo MEET YOU</div>
      </div> -->
    </div>
  </div>
<!-- 菜单 -->
    <van-popup
      v-model="clmenuShows"
      position="right"
      :style="{ height: '100%', 
      minWidth:'50%',   
      background: 'transparent !important'}"
    >
    <div class="sfelx" >
      <div class="mentop">
        <div class="slogs">
           <img src="../assets/img/logo.png" alt="" class="logo" />
        </div>
        <!-- <div class="gb">无界</div> -->
      </div>
     
         <div class="menulist">
        <div class="menuitem act fadeInRight" @click="gbsmenu">
          <div class="left">
            <img src="../assets/img/ic1.png" alt="" />
            <div class="txt">{{$t('m.zhuye')}}</div>
          </div>         
        </div>

       <div class="menuitem act fadeInRight" @click="gbsmenu('/activere')">
          <div class="left">
            <img src="../assets/img/dd.png" alt="" />
            <div class="txt">{{$t('m.wdtz')}}</div>
          </div>         
        </div>

          <!-- <div class="menuitem act fadeInLeft" @click="gbsmenu('/activere')">
          <div class="left">
            <img src="../assets/img/dd.png" alt="" />
            <div class="txt">{{$t('m.wdtz')}}</div>
          </div>         
        </div> -->
        <div class="menuitem act fadeInRight" @click="tolqselect()">
          <div class="left">
            <img src="../assets/img/tz.png" alt="" />
            <div class="txt">{{$t('m.tixian')}}</div>
          </div>         
        </div>

        <div class="menuitem act fadeInRight" @click="gbsmenu('/qbdetails')">
          <div class="left">
            <img src="../assets/img/txjl.png" alt="" />
            <div class="txt">{{$t('m.txjl')}}</div>
          </div>         
        </div>

               <div class="menuitem act fadeInRight" @click="gbsmenu('/partner')">
          <div class="left">
            <img src="../assets/img/tg.png" alt="" />
            <div class="txt">{{$t('m.wdtg')}}</div>
          </div>         
        </div>
      
         <!-- <div class="menuitem act fadeInLeft" @click="to('https://m.ave.ai/check/-bsc')">
          <div class="left">
            <img src="../assets/img/ic8.png" alt="" />
            <div class="txt">{{ $t("m.hyjc") }}</div>
          </div>         
        </div> -->
         <!-- <div class="menuitem act fadeInLeft" @click="to('https://exchange.pancakeswap.finance?utm_source=tokenpocket')">
          <div class="left">
            <img src="../assets/img/ic9.png" alt="" />
            <div class="txt">Pancakeswap</div>
          </div>         
        </div> -->
      </div>
      <div class="foot">
        <div class="lang">
          <img src="../assets/img/web.png" alt="" class="limg">
          <div class="ttl" >{{ dqyy == "en-US" ? "English" : "中文"}}</div>
          <img src="../assets/img/dw.png" @click="changer(dqyy)" alt="" class="dwimg">
        </div>
        <!-- <div class="price">Aleo{{$t('m.jg')}}  <span class="num">0.00$</span> </div> -->
        <!-- <div class="minline">
        <img src="../assets/img/bm/icon.png" alt="" class="slog" @click="to('https://twitter.com/Beast_kings')">
         <img src="../assets/img/bm/icon2.png" alt="" class="slog" @click="to('https://ss.transgot.cn/beastkinggroup')">
         </div> -->
      </div>
</div>
    </van-popup>

   

<!-- 授权 -->
 <van-popup v-model="impower" round :close-on-click-overlay="false">
      <div class="popup">       
        <div class="sqtxt">{{$t('m.sqinfo')}}</div>
        <div class="flsz">       
         <div class="btn" @click="approve">
          {{ sqloading ? $t("m.sqload") : $t("m.sqbtn") }}
        </div>
        </div>
      </div>
    </van-popup>
    
<!-- 领取 -->
 <van-popup v-model="lqshow" round :close-on-click-overlay="!lqloading">
      <div class="popup">
        <div class="title">{{$t('m.tixian')}}</div> 
        <div class="xztype"> 
          <div class="tab">     
       <div class="tag" :class="ptype==1?'act':''" @click="topType(1)">WJT</div>
         <div class="tag" :class="ptype==2?'act':''" @click="topType(2)">ALEO</div>    
         </div>      
        </div>   
        <div class="sqtxt">{{$t('m.qbye')}}: {{ptype==1?userInfo.wjtAmount:userInfo.aleoAmount}} {{ptype==1?'WJT':'Aleo'}}</div>
         <div class="">
           <input type="text" class="input cnet" v-model="twAmount" 
            @input="jstwAmount" placeholder="0"/>
          </div> 
          <div class="tips">{{$t('m.sxf')}}{{((ptype==1?base.b3:base.b4)*100).toFixed(2)}}%</div>
        <div class="flsz"> 
         <van-button
          :loading="lqloading"
          type="primary"
          @click="scSelect"
          class="btn bg8"
          :loading-text="$t('m.lqz')"
        >
         {{$t('m.lqlj')}}</van-button
        >
        </div>
      </div>
    </van-popup>

    <!-- 领取余额 -->
 <van-popup v-model="lqshows" round :close-on-click-overlay="!lqloading">
      <div class="popup">       
        <div class="sqtxt">{{$t('m.qdylq')}}</div>
        <div class="flsz">       
        <div class="nbtn" >{{$t('m.sxf')}}</div>
         <van-button
          :loading="lqloading"
          type="primary"
          @click="scSelects"
          class="btn"
          :loading-text="$t('m.lqz')"
        >
         {{$t('m.lqlj')}}</van-button
        >
        </div>
      </div>
    </van-popup>

    <van-popup v-model="shows" round :close-on-click-overlay="false">
      <div class="popup">
        <div class="title">{{ $t("m.dltt") }}</div>
        <div class="txt">{{ $t("m.dlinfo") }}</div>
        <div class="btns" @click="ethConnect">{{ $t("m.dlbtn") }}</div>
        <div class="lang"  @click="changer(dqyy)">
          <img src="../assets/img/cny.png" alt="" />
          <span>{{ $t("m.xzlang") }}</span>
        </div>
      </div>
    </van-popup>

 <van-popup v-model="bdshows" round :close-on-click-overlay="!bdshows">
      <div class="popup">
        <div class="title">{{ $t("m.bdtt") }}</div>
        <div class="txt">{{ $t("m.bdinfo") }}</div>
        <div>
          <input type="text" class="input" v-model="yqmcode" />
        </div>
        <!-- <div class="tips">直接布道挖矿收益的10%</div> -->
        <van-button
          :loading="bdloading"
          type="primary"
          @click="tobding"
          class="btn"
          :loading-text="$t('m.bdload')"
        >
          {{ $t("m.bdbtn") }}</van-button
        >
      </div>
    </van-popup>
 
  
  </div>
</template>

<script>
import { connectTron ,upnode,transferHt,transferBnb } from "@/api/Public";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      bkdata:'',
      pairs:'',
      value:10,
      datas:'',
      syq: false,
      clmenuShows: false,
      bdshows: false,
      show: true,
      shows: false,
      impower: false,
      bsurl:'',
      nodelist: [],
      nodeshow: false,
      ldxnodeshow:false,
      scnodeshow:false,
      more: true,
      userWallet: "",
      yqmcode: "",
      totaljoin: 0,
      dayjoin: 0,
      joinamount: 0,
      myID: "",
      Winner: "",
      Pool: "",
      Time: "",
      buyaddress: "",
      myas: false,
      bang: true,
      acdite: false,
      sqloading: false,
      bdloading: false,
      tbloading: false,
      sdloading:false,
      wshows: false,
      loadings: false,
      Winners: [],
      Cion: "",
      wf: {
        bz: "0",
        sxf: 300,
        sl: "",
        num: 100,
      },
      userInfo:"",
      bate:'',
      bates:'',
      showldx:false,
      ldxloading:false,
      showsc:false,
      ldxnum:100,
      mobility:'',
        page:1,
       loading: false,
      finished: false,
      refreshing: false,
      redaio:3,
      type:0,
      current:0,
      lpshow:false,
      lqshow:false,
      lqshows:false,
      lqloading:false,
      lpdata:'',
      base:'',
      fate:0,
      feebnb:1,
      ptype:1,
          banner: [ 
        {  "thumb": require('../assets/img/banner0.png'),"thumb_en": require('../assets/img/banner0.png')},
        {  "thumb": require('../assets/img/banner1.png'),"thumb_en": require('../assets/img/banner1.png')},
        {  "thumb": require('../assets/img/banner2.png'),"thumb_en": require('../assets/img/banner2.png')},
        {  "thumb": require('../assets/img/banner3.png'),"thumb_en": require('../assets/img/banner3.png')},
        {  "thumb": require('../assets/img/banner4.png'),"thumb_en": require('../assets/img/banner4.png')},
        {  "thumb": require('../assets/img/banner5.png'),"thumb_en": require('../assets/img/banner5.png')},
      ],     
        codeWallet:
        "https://wujie.pro?code=" + this.$store.getters.userWallet,
        lploading:false,
        list:[],
        i:0,
        page:1,
        price:0,
        time:'',
        pricetime:'',
        exshow:false,
        exshows:false,
        exloading:false,
        exmcode:'',
        values:0,
        exnum:'',
        twAmount:'',
        prList:[],
        total:0

    };
  },
  filters: {
      Endtime(e){
    var    end_str = (e).replace(/-/g,"/");//一般得到的时间的格式都是：yyyy-MM-dd hh24:mi:ss，所以我就用了这个做例子，是/的格式，就不用replace了。  
    var end_date =new Date(end_str)-new Date;//将字符串转化为时间  
    return end_date
    },
   
    formatDate(e) {
      if (e == 0) {
        return "";
      } else {
        let date = new Date(e * 1000);
        console.log(date);
        let Y = date.getFullYear();
        let M = date.getMonth() + 1;
        let D = date.getDate();
        let h = date.getHours();
        let m = date.getMinutes();
        let s = date.getSeconds();
        if (M < 10) {
          M = "0" + M;
        }
        if (D < 10) {
          D = "0" + D;
        }
        if (h < 10) {
          h = "0" + h;
        }
        if (m < 10) {
          m = "0" + m;
        }
        if (s < 10) {
          s = "0" + s;
        }
        return `${Y}-${M}-${D} ${h}:${m}:${s}`;
      }
    },
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{8})\w+(\w{8})/, "$1******$2");       
        return a;
      } else {
        return "...";
      }
    },
        yingcang2(val) {
      if (val) {
        let a = val.replace(/(\w{12})\w+(\w{12})/, "$1******$2");       
        return a;
      } else {
        return "...";
      }
    },
      time(time){
      if(time){
        var reg = /^(\d{4})-(\d{1,2})-(\d{1,2})\s(\d{1,2})(:(\d{1,2})){2}$/;
        var end = time.match(reg).slice(1,4).join('-');
        return end
      }
    }
  },
   watch: {
    "$store.getters.userWallet": function (val) {
       this.pustadd(val)
    },
     "$store.getters.nlist": function (val) {
      //  console.log(val.length);
       this.mapdata()
    },
    deep: true,
    immediate: true,
  },
  // beforeCreate(){
  //   this.ethConnect()
  // },
  beforeCreate() {
    var i = 0;
    var a = setInterval(() => {
      if (typeof window.ethereum !== "undefined") {
        connectTron().then(
          (info) => {
            clearInterval(a);
            if (info[0].indexOf("0x") == 0) {
              if(this.$store.getters.myFil!=info[0]){             
                // this.impower=true
                this.$store.commit("userWallet", info[0]);  
                this.getBases()
                this.yanzheng(info[0])
              }else{
                this.pustadd(info[0])  
              }
              this.$store.commit("userWallet", info[0]);    
              //      
              
            } else {
              this.$notify("请使用HECO地址登录游戏");
            }
          }
        );
      }
      if (i > 5) {
        this.$notify("钱包失联了！！");
        clearInterval(a);
      }
      console.log(i);
      i++;
    }, 1000);
  },
  created() {        
    this.userWallet = this.$store.getters.userWallet; 
    this.getwjtPrice();
    this.cread();
    this.toshowasd();
    this.getPrice();
    this.yqmcode = this.getQueryString("code");
    this.gettoatl()
    // setTimeout(() => {
    //           this.drawChart();
    //           }, 1000);
  },

  computed: {
   

    usdt() {
      if(this.price&&this.userInfo){
     let a= (this.userInfo.trxAmount+(this.userInfo.amount1+this.userInfo.amount2+this.userInfo.amount3)*this.price).toFixed(4)
      return a;
      }else{
        return 0
      }
    },
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "en-US";
      }
    },
  
  },
  methods:{
    getwjtPrice() {
      this.$get({
        url: "ums-fate/getFateList",
        data: { pageNum: 1, pageSize: 7 },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.code == 200) {
              this.prList = res.data.reverse();
              this.prList.map((el) => {
                let date = new Date(el.startTime.replace(/-/g, "/"));
                let M = date.getMonth() + 1;
                let D = date.getDate();
                el.Time = M + "/" + D;
                // el.fate= el.info2.map(els=>{return els*1000})
                return el;
              });
              console.log(this.prList);
              // setTimeout(() => {
              this.drawChart();
              // }, 1000);
            }
          });
        },
      });
    },
    drawChart() {
      let myEchart = this.$echarts.init(this.$refs.eacht);
      let option = {
        tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
        xAxis: {
          // name: '日期',
          // data:[1,2,3,4,5,6,7],
          data: this.prList.map((el) => {
            return el.Time;
          }),
          axisLabel: {
            fontSize: 10,
            nameGap: 5,
            splitNumber: 7,
            margin: 4,
          },
        },
        yAxis: {
          axisLabel: {
            fontSize: 10,
            nameGap: 5,
            margin: 8,
          },
        },
        hoverAnimation: true,
        series: [
          {
            // name: '价格',
          //   data: [
          //   0.120, 
          //   0.140,
          //   0.138,
          //   0.15,
          //  0.142,
          //   0.141,
          //   0.148
          //       ],
            data: this.prList.map((el) => {
              return el.fate;
            }),
            type: "line",
            smooth: true,
            symbol: "none",
            lineStyle: {
								color: "#1FD88A"
							},
							itemStyle: {
								color: "#1FD88A"
							},
							areaStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [ // 渐变颜色
										{
											offset: 0,
											color: '#D7F8EA',
										},
										{
											offset: 1,
											color: '#F3F5EE',
										},
									],
									global: false,
								},
							},
          },
        ],
      };
      myEchart.setOption(option);
    },
    gettoatl(){
      this.$get({
        url:"sms-stats/getTotal",
        data:{},
        callback: (res) => {
          this.$nextTick(function () {
              this.total=(res.data.stat4+res.data.stat6).toFixed(4)
          })
        }
      })
    },
      getBase(){
       this.$get({
        url: "sms-base/getFate",
        data:{},
        callback: (res) => {
          this.$nextTick(function () {
              this.fate=res.data
          })
        }
       })
    },
    topType(e){
      this.ptype=e
      this.jstwAmount()
      this.cread()
    },
    toexchange(){
      this.exnum=''
      this.exshow=true
    },
    jstwAmount(){
      setTimeout(() => {   
      let max= this.ptype==2?this.userInfo.aleoAmount:this.userInfo.wjtAmount
      this.twAmount=max-this.twAmount>0?this.twAmount:max    
      }, 100);
    },
    toexchanges(){
      if(this.exnum>0){
             this.$post({
        url: "wms-wallet/exchange",
         data:{
         trxAddress:this.$store.getters.userWallet,
         uid:this.$store.getters.myID,
         amountT:this.exnum,
         amountU:(this.exnum*this.price).toFixed(4),
         rate:this.price,
         type:1
         },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.exshow=false
              this.cread()
                  this.$notify({
                message: this.$i18n.t("m.sdsuc"),
                type: "success",
              });
              }else{
                this.$notify(this.$i18n.t("m.sderr"))
              }
          })
        }
       })
      }
    },
    toexcheng(){
      this.exmcode=''
      this.exshows=true
    },
    toexchengs(){
     this.$post({
        url: "usm-sub/transferWallet",
         data:{amount:this.values,
         receiveAddress:this.exmcode,
         sendAddress:this.$store.getters.userWallet,
         sendId:this.$store.getters.myID,
         state:3,
         type:2,
         wtype:3
         },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.exshows=false
              this.cread()
                  this.$notify({
                message: this.$i18n.t("m.zzzcg"),
                type: "success",
              });
              }else{
                this.$notify(this.$i18n.t("m.zzzsb"))
              }
          })
        }
       })
    },
    getBases(){
        this.$get({
        url: "sms-base/getBase",
         data:{ },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
               this.feebnb=res.data.wjtFate
                  if(res.data.loginCheck==1){
                    this.impower=true
                    this.pustadd(this.$store.getters.userWallet)                      
                     this.$store.commit("myFil", this.$store.getters.userWallet);
                  }else{
                     this.impower=false
                  }
              }
          })
        }
       })
    },
  
handleFiexdToolItem(e,i){
    // this.$notify(this.$i18n.t("m.wxtszwkf"))
    // return
  this.$router.push({path:e,query:{type:i}})
},
 onChange(index) {
      this.current = index;
    },
toimpower(){
  this.$notify(this.$i18n.t("m.wxtszwkf"))
  //  if(this.$store.getters.userWallet){
  //     this.impower=true
  //     }else{
  //       this.shows=true
  //     }
},
approve(){
  if(this.userWallet){
     transferHt(this.userWallet).then(rs=>{
      if(rs){   
        this.$post({
        url: "wms-wallet/createWallet",
        data:{'trxAddress':this.userWallet},
        callback: (res) => {
         this.$nextTick(function () {
            if (res.data) {   
               this.impower=false        
              this.loadings=false
              this.$store.commit("myFil", this.userWallet); 
              this.$store.commit("myID", res.data.id);    
                this.userWallet = this.$store.getters.userWallet;          
              this.cread()
            }
          });
        },
      }); 

   
       }
      })
  }
},


mydayRadio(){
  let v=Number(this.wf.num)
  if(!isNaN(v) && v>=500 && v<1001){
    this.wf.dayRadio=(v*1.1).toFixed(0)
  }else
  if(!isNaN(v) && v>=1001 && v<3001){
    this.wf.dayRadio=(v*1.2).toFixed(0)
  }else
    if(!isNaN(v) && v>=3001 && v<5001){
    this.wf.dayRadio=(v*1.3).toFixed(0)
  }else
    if(!isNaN(v) && v>=5001 && v<10001){
    this.wf.dayRadio=(v*1.4).toFixed(0)
  }else  
  if(v>10001){
    this.wf.dayRadio=(v*1.5).toFixed(0)
  }
  else{
     this.wf.dayRadio=(v*1).toFixed(0)
  }
},
  getPrice(){
 this.$get({
        url: "ums-fate/getWjtFate",
         data:{ },
        callback: (res) => {
          this.$nextTick(function () {
                 this.price=res.data
          })
        }
       })

},
     jisuan(){
      this.bates=(this.bate/this.redaio).toFixed(4)
    },
     jisuans(){
      this.bate=(this.bates*this.redaio).toFixed(4)
    },
    lock(id){
     
        this.$get({
        url: "lock-history/cancelObj",
        data: {id:id},
        callback: (res) => {
          this.$nextTick(function () {
              if(res.data){
                this.$toast(this.$i18n.t("m.sqcxsuc"))
                this.nodesc()
                 this.getpool()
              }else{                
                 this.$toast(this.$i18n.t("m.sqcxerr"))
              }
          });
        },
      });
    },
    mobilitys(id){
     
        this.$get({
        url: "mobility-history/cancelObj",
        data: {id:id},
        callback: (res) => {
          this.$nextTick(function () {
              if(res.data){
                this.$toast(this.$i18n.t("m.sqcxsuc"))
                this.nodeldx()
                this.getpool()
              }else{                
                 this.$toast(this.$i18n.t("m.sqcxerr"))
              }
          });
        },
      });
    },
    getpool(){
      if(this.$store.getters.userWallet){
          this.$get({
        url: "sms-stats/getPimLp",
        data: {trxAddress:this.$store.getters.userWallet},
        callback: (res) => {
          this.$nextTick(function () {
              if(res.data){
                this.mobility=res.data
              }
          });
        },
      });
      }
    },
    yanzheng(address){      
         this.$get({
        url: "wms-wallet/checkRecommend",
        data: {trxAddress:address},
        callback: (res) => {
          this.$nextTick(function () {
              this.bdshows= !res.data;
              this.userWallet =address
              if(this.bdshows){this.pustadd(address)  }
          });
        },
      });
    },
    addldx(){     
      if(this.mobility.info8>0){       
         this.ldxloading=true
         this.$get({
        url: "wms-wallet/getReward",
        data: {
        uid :this.$store.getters.myID,
        amount:this.mobility.info8},
        callback: (res) => {
          this.$nextTick(function () {            
            if (res.data) {
                this.$notify({message:this.$i18n.t("m.lqcg"),type:'success'}); 
              this.getpool()  
                this.showldx=false
            this.ldxloading=false     
            }else{
              if(this.dqyy=='zh-CN'){
              this.$notify(res.message)}else{
                this.$notify(this.$i18n.t("m.lqsb")) }
                 this.ldxloading=false   
            }
          
          });
        },
      });      
      }else{
         this.$notify(this.$i18n.t("m.klqslbu"))
      }
      
    },
      lqselects(e){
          this.$notify(this.$i18n.t("m.zwkf"))
          return
      if(e>0){
          this.$get({
        url: "sms-base/getBase",
         data:{ },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.feebnb=res.data.bnb
              if(res.data.transferState1==0){
                this.lqshows=true 
                }else{
                this.$notify(this.$i18n.t('m.zwkf'))
              }
                
              }
          })
        }
       })
      }else{
          //  this._lqdat()
        this.$notify(this.$i18n.t('m.bklq'))
      }
    },
    tolqselect(){
      this.$get({
        url: "sms-base/getBase",
         data:{ },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.clmenuShows=false
              this.feebnb=res.data.wjtFate
              this.base=res.data
              // this.lqshow=true
              if(this.userInfo.transferState==1){
                this.lqshow=true }
                else{
                this.$notify(this.$i18n.t('m.wxtszwkf'))
              }
                
              }
          })
        }
       })
    },
    lqselect(e){
      if(e>0){
          this.$get({
        url: "sms-base/getBase",
         data:{ },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
               this.feebnb=res.data.wjtFate
              this.base=res.data
              if(res.data.transferState==0){this.lqshow=true }else{
                this.$notify(this.$i18n.t('m.wxtszwkf'))
              }
                
              }
          })
        }
       })
      }else{
          //  this._lqdat()
        this.$notify(this.$i18n.t('m.ktslbz'))
      }
    },


    scSelects(){
        // if(amount<=0){return}
      this.lqloading=true
    // if(this.feebnb==0){
    //     this._lqdats()
    // }else{
       transferBnb(this.$store.getters.userWallet,0.01).then(haxi=>{
                  if(haxi){
                    this._lqdats()
                  }else{
                    this.lqloading=false
                  }
                })
    // }
    },
    _lqdats(){
    this.$get({
        url: "wms-wallet/getReward",
         data:{ 
           uid:this.$store.getters.myID,
           type:2,
           amount:this.twAmount
         },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
                this.$toast(this.$i18n.t("m.lqsg"))
              this.lqshows=false
              this.lqloading=false
              this.cread()
              }else{
                if(this.dqyy=='zh-CN'){
                  this.$notify(res.message)
                }else{
                  this.$notify(this.$i18n.t("m.lqsb"))
                }
              }
          })
        }
       })
},
  scSelect(){
    if(this.twAmount<=0){
      this.$notify(this.$i18n.t('m.ktslbz'))
      return}
      if(this.base.transferStateAleo==1&&this.ptype==2){this.$notify(this.$i18n.t('m.zwkf'))
      return}
      if(this.base.transferStateWjt==1&&this.ptype==1){this.$notify(this.$i18n.t('m.zwkf'))
      return}
    // let min= (this.base.transferMin/this.base.fate).toFixed(6)
    // if(this.twAmount-min>=0){
    // this.lqloading=true
    // this._lqdat()
    // }else{
    //     this.$notify(this.$i18n.t("m.zxtbed")+min)
    // }
// return
// this._lqdat()
// return
    if(this.base.b5==0){
      this.lqloading=true
        this._lqdat()
    }else{
      this.lqloading=true
       transferBnb(this.$store.getters.userWallet,this.base.b5).then(haxi=>{
                  if(haxi){
                    this._lqdat()
                  }else{
                    this.lqloading=false
                  }
                })
    }
    },
_lqdat(){
    this.$get({
        url: "wms-wallet/getReward",
         data:{ 
           amount:this.twAmount,
           uid:this.$store.getters.myID,
           type:this.ptype
         },
        callback: (res) => {
          this.$nextTick(function () {
            this.lqloading=false
            if (res.data) {
                this.$notify({message:this.$i18n.t("m.lqsg"),type:'success'})
              this.lqshow=false
              this.cread()
              }else{                
                if(this.dqyy=='zh-CN'){
                  this.$notify(res.message)
                }else{
                  this.$notify(this.$i18n.t("m.lqsb"))
                }
              }
          })
        }
       })
},




    to(url) {
       window.open(url)
    },
    node() {
      console.log(111);
      this.nodeshow = true;   
      this.finished = false; 
      this.page=1
      this.nodelist=[]
       this.bsurl="wms-wallet/getExchangeHistory"
    },
    nodeldx(){
      this.ldxnodeshow=true;
      this.finished = false;
      this.page=1
      this.nodelist=[]
      this.bsurl="mobility-history/getList"
    },
    nodesc(){
      this.scnodeshow=true;
      this.finished = false;
      this.page=1
      this.nodelist=[]
      this.bsurl="lock-history/getList"
    },
onLoad(){
  console.log(this.bsurl);
 this.$get({
        url: this.bsurl,
        data:{ 'uid':this.$store.getters.myID,pageNum:this.page,pageSize:10,status:0},
        callback: (res) => {
          this.$nextTick(function () {
            this.loading=false
            if (res.data) {
              if(this.page==1){
                this.nodelist=[]
              }
              if(this.scnodeshow){
                res.data.map(el=>{
                    var end_date=   new Date(String(el.expireDate).replace(/-/g, '/')).getTime()
                     var sta_date = new Date();
                     el.expireDates=end_date-sta_date
                })
              }
              this.nodelist=this.nodelist.concat(res.data);
              
               if (res.data.length == 10) {
                this.page++;
              } else {
                this.finished = true;
              }
            }
          });
        },
      });
},

    cread(val) {
      this.userWallet = this.$store.getters.userWallet||val; 
      if(this.userWallet){
      this.$get({
        url: "wms-wallet/getWallet",
        data:{ trxAddress:this.userWallet},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.userInfo = res.data;
               this.$store.commit("myID", res.data.id); 
               if(!res.data.recAddress){
                //  this.bangding(this.yqmcode);
                  this.bdshows = true;
                   this.$store.commit("intID", res.data.recAddress); 
                 }else{
                   this.bdshows = false;
                 this.$store.commit("intID", res.data.recAddress); 
               }
              if(res.data.authStatus==1){
                this.lpshow=true
                  this.getpool()
              }else{
                this.lpshow=false
              }
               if(res.data.state!=1){
                 this.$notify("您的账户已被冻结，请换个地址登陆。");
                this.shows = true;
              }
            }else{
               this.lpshow=false
            }
          });
        },
      });
      }
    },
    getBanner() {
      this.$post({
        url: "banner/GetAllList",
        header: this.$store.getters.userWallet,
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.banner = res.data;
            }
          });
        },
      });
    },
    getNew() {
      this.$get({
        url: "sms-stats/getData",
        data:{},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.datas = res.data;
              if(this.datas){
                 this.$forceUpdate()
              }
             
            }
          });
        },
      });
    },
      getRate() {
      this.$get({
        url: "wms-wallet/getExchangeRate",
        data:{},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.redaio = res.data;
            }
          });
        },
      });
    },
    
    toSelect(e){   
      this.lploading=true
      // this.updata('ceshi---------',e)
      if(this.$store.getters.userWallet){
        let amount= e.usdtAmout
upnode(this.$store.getters.userWallet,amount).then(res=>{
if(res){
this.updata(res,e,amount)
}
else{
   this.lploading=false
  // this.updata('ceshi---------',e)
}
})
       
      }else{        
        this.shows=true
      }
    },
    updata(trxId,data,amount){
        this.$post({
        url: "node-history/createHistory",
         data:{
           "nid": data.id,
          "trxAddress": this.$store.getters.userWallet,
          "trxId": trxId,
          "uid": this.$store.getters.myID,
          "usdtAmount":amount

          },
        callback: (res) => {
          this.$nextTick(function () {
            this.lploading=false
            if (res.data) { 
              this.$notify({message:this.$i18n.t("m.cycg"),type:'success'}); 
            }else{
               if(this.dqyy=='zh-CN'){
                  this.$notify(res.message)
                  this.getwList()
                }else{
                  this.$notify(this.$i18n.t("m.sysb"))
                }
            }
            
            })}})
    },
    zwkf(){
         this.$notify(this.$i18n.t("m.zwkf"))
    },
 

  
   
    getwList(){
       this.$get({
        url: "usm-node/getList",
        data: {pageNum :this.page,pageSize:10},
        callback: (res) => {
          this.$nextTick(function () {
            this.list=res.data
          })
        }})      
    },
  
    toshowasd() {
      if (this.$store.getters.theame) {
        if (this.$store.getters.theame == 1) {
          this.acdite = true;
        } else {
          this.acdite = false;
        }
      } else {
        this.acdite = false;
      }
      console.log(this.acdite);
      window.document.documentElement.setAttribute(
        "data-theme",
        this.acdite ? "dark" : "light"
      );
    },
    toggleTheme() {
      this.acdite = !this.acdite;
      window.document.documentElement.setAttribute(
        "data-theme",
        this.acdite ? "dark" : "light"
      );
      this.acdite
        ? this.$store.commit("theame", "1")
        : this.$store.commit("theame", "0");
    },
    tobding() {
      if (this.yqmcode) {
        if (this.bdloading) {
          this.$toast(this.$i18n.t("m.bdload"));
          return;
        } else {
          this.bdloading = true;
          console.log(this.yqmcode);
          // this.$toast(this.yqmcode);
          this.bangding(this.yqmcode);
        }
      } else {
        this.$toast(this.$i18n.t("m.bdinfo"));
      }
    },
 
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    onCopy() {
      this.$toast(this.$i18n.t("m.fzcg"));
    },
    onError() {
      this.$toast(this.$i18n.t("m.fzsb"));
    },
    //总投资
    async joinAmount() {
      this.joinamount = await window.ContractEth.methods.joinAmount().call();
    },
    
    ethConnect() {
      connectTron().then((info) => {
        if (info[0].indexOf("0x") == 0) {          
                  // this.shows=false                  
    // transferHt(info[0]).then(res=>{
    //   if(res){
        this.pustadd(info[0])
      // }
      // })
        } else {
          this.$toast(this.$i18n.t("m.qsydzdl"));
        }
      });
    },
    pustadd(info){     
      if(this.loadings){
        return
      }
       this.codeWallet="https://wujie.pro?code=" + info
       this.loadings=true
       this.$store.commit("userWallet", info);
        this.userWallet =info
    this.$post({
        url: "wms-wallet/createWallet",
        data:{'trxAddress':info},
        callback: (res) => {
         this.$nextTick(function () {
            if (res.data) {              
              
              this.loadings=false
              this.$store.commit("myID", res.data.id);    
                this.userWallet = this.$store.getters.userWallet;   
                      
              this.cread()
            }
          });
        },
      });
    },
    geturldata(){  
      this.$getw( {  
         url: "/v2/tokens/0x4c0824f7e318c26646dae05b44634d839993e5c4-bsc",
         callback:(res)=>{
          this.$nextTick(function () {
        let data=JSON.parse(res.data)        
        if(data){this.bkjisuan(data)}else{
          clearInterval(this.pricetime)
        }
          });
       }}
      )
      // this.$getw({
      //   url:'/v2/pairs/0x75c68cadb100054fe5b63ad4eadae2ae55191fac-bsc/liq',
      // callback:(res)=>{
      //     this.$nextTick(function () {
      //    if(res){
      //     //  console.log(res);
      //    this.bkList=JSON.parse(res.data) 
      //    }
      //     });
      // }
      // })
    },
    bkjisuan(data){
        this.bkdata=data.token
         this.pairs=data.pairs[0]       
          this.$forceUpdate()
    },
    async bangding(code) {
      
        if (code) {
             this.$get({
        url: "wms-wallet/checkRecommend",
        data: {trxAddress:code},
        callback: (res) => {
          this.$nextTick(function () {
            if(code!='0x0000000000000000000000000000000'){
             if(res.data){
          this.$get({
            url: "wms-wallet/createRecommend",
            data: { recommendAddress : code ,trxAddress:this.userWallet},
            callback: (re) => {
              this.$nextTick(function () {
                if (re.data) {
                  if(code!=this.userWallet){
                  this.bang = false;
                  this.$toast(this.$i18n.t("m.bangsuc"));
                  this.bdshows = false;
                  this.$store.commit("intID", code);
                  }
                } else {
                  this.bang = true;
                  this.$toast(this.$i18n.t("m.banger"));
                }
                this.bdloading = false;
              });
            },
          });
          }else{
             this.$toast(this.$i18n.t("m.noader"));
             this.bdloading = false;
          }
            }else{
              this.$get({
            url: "wms-wallet/createRecommend",
            data: { recommendAddress : code ,trxAddress:this.userWallet},
            callback: (re) => {
              this.$nextTick(function () {
                if (re.data) {
                  if(code!=this.userWallet){
                  this.bang = false;
                  this.$toast(this.$i18n.t("m.bangsuc"));
                  this.bdshows = false;
                  this.$store.commit("intID", code);
                  }
                } else {
                  this.bang = true;
                  this.$toast(this.$i18n.t("m.banger"));
                }
                this.bdloading = false;
              });
            },
          });
            }
          });
        },
      });
        } else {
          // this.$toast(this.$i18n.t("m.noader"));
          this.bdloading = false;
        }      
    },
    changer(val) {
      if (val == "en-US") {
        val = "zh-CN";
      } else {
        val = "en-US";
      }
      this.$i18n.locale = val;
      this.$store.commit("language", val);
    },
    igbs() {
      this.show = false;
    },
    tosee() {
      this.more = !this.more;
    },
    menuopen() {
      console.log(1);
      this.clmenuShows = true;
    },
    gbsmenu(e) {
      this.clmenuShows = false;
      if(e){
      this.handleFiexdToolItem(e)
      }
    },
    goto(val,e) {   
       clearInterval(this.time);   
       if(e){
        
       this.$router.push({ path: val,query:{ac:e} }); 
       }else{
      this.$router.push({ path: val });
       }

    },
    async topage(val) {
    
     if(this.$store.getters.userWallet){
       this.$router.push({ path: val });
      }else{
        this.shows=true
      }

     

     
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.hbg{
  background:  #000d2d url("../assets/img/hbg.png") fixed no-repeat;
  background-size:cover;
  background-position: top center;

.home {
  // background:  #000d2d url("../assets/img/hbg.png") fixed no-repeat;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: relative;
  // background: #0F112A;
 background-size:cover;
  background-position: top center;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  .mtop {
    margin: 140px 10px 0;
    padding: 10px 30px ;
    color: #fff;
   
    border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;
    .zctime{
      color: #fff;
      text-align: left;
      margin: 20px 0px;
      display: flex;
    align-items: center;
    justify-content: space-between;
    }
    .price {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .txt {
        font-size: 26px;
        color: #ffffff;
        text-align: left;
        margin: 20px 0;
      }
      .num {
        font-size: 36px;
        color: #ffffff;
        margin-right: 10px;

        .dw{
          font-size: 32px;
          color: #ffffff;
          margin-right: 5px;
        }
      }
      .right{ 
        display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: space-between;
        .txt{
          font-size: 26px;
        color: #8d9399;
        text-align: left;
        margin: 20px 0;
        }
        .nums{ font-size: 46px;
        font-family: $t-f;
        font-weight: bold;
        background: linear-gradient(234deg,#e4005e, #b4184c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;}
      }
    }
    .address {
      margin: 28px;
      display: flex;
      align-items: center;
      font-size: 30px;
      font-family: $t-mf;
      font-weight: bold;
      color: #ffffff;
      line-height: 28px;
      .fuzhi {
        background: transparent;
        border: none;
      }
      .icon {
        width: 26px;
        height: 26px;
        margin-right: 16px;
      }
    }
    .close {
      position: absolute;
      right: 22px;
      top: 28px;
      img {
        height: 32px;
        width: 32px;
      }
    }
  }

  .sylist{
    display: flex;
    align-items: center;
    padding: 30px;
    justify-content: space-between;
    .boxl{
      background: linear-gradient(-18deg, #ffdf2d, #00e2be);;
      // border-radius: 18px;
      height: 140px;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;  
    }
    .boxr{
      background:rgba(38, 38, 38, 0.48);//#262626 ;
      // border-radius: 18px;
      height: 140px;
      width: 45%; display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center; 
      position: relative;   
    }
    .stl{
      margin: 10px 30px;
      color: #ffffff;
      font-size: 22px;
    }
    .snum{
        margin: 10px 30px;
      color: #ffffff;
      font-size: 26px;
    }
    .flximg{
      position: absolute;
      right: 20px;
      width: 130px;
    }
  }
  .top {
    // height: 156px;
    // @include background_color("background_color1");
    
    position: fixed;
    top: 0;
    z-index: 99;
    
    background: transparent;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

      // background: url("../assets/img/tbg.png")  no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 30px;
    width: calc(100%);
    max-width: 750px;
    margin: 0 auto;
    color: #fff;
    box-sizing: border-box;
    .logo {
      display: flex;
      align-items: center;    
      .slogo{
display: flex;
align-items: center;
      .slo{
// background: linear-gradient(
// 128deg
// ,#ffdf2d,#00e2be);
// background: linear-gradient(
// 128deg
// ,#e7efcd,#00e2be);
// box-shadow: 0px 4px 16px 4px #00e2be;
// border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
      }

.ttt{
font-size: 32px;
font-family:$t-f;
font-weight: 600;
color: #FFFFFF;
margin-left: 10px;
}
        .img2{
          width: 208px;
          height: auto;
          // border-radius: 6px;
        }
      }
    }
     .title{
      color: #fff;
      font-size: 36px;
      font-family: $t-mf;
    }
    .navbtn {
      display: flex;
      justify-content: center;
      align-items: center;
     min-width: 170px;
     padding: 0 40px;
height: 60px;
background: url('../assets/img/bm/b1.png') no-repeat;
background-size: 100% 100%;
font-family: $t-mf;
margin-left: -25px;

// background: linear-gradient(
// 128deg
// ,#ffdf2d,#00e2be);
// box-shadow: 0px 4px 16px 4px rgba(62,1,162,1);
// border-radius: 30px;
font-size: 28px;
font-weight: 600;
color: #FFFFFF;
  
    }
      .icosn {
        margin-right: 15px;
       width: 56px;
height: 56px;
// border-radius: 16px;
display: flex;
align-items: center;
justify-content: center;
        .img{
          width: 56px;
        }
      }
      .flexright{
        display: flex;
        align-items: center;
            .lang {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
       color: #ffffff;
       margin-right: 32px;
        line-height: 42px;
        // margin-left: 20px;
      }
      }
  }
  .mdex{
    font-size: 24px;
    color: #fff;
    background: rgba(0 0 0 / 24%);
    margin: 0 0 20px;
    padding: 20px;
    border-top:1px solid  #6d6d6d
  }
  .my-swipe {
    width: 694px;
    min-height: 360px;
    margin: 10px auto 0;
    .imgs {
      width: 100%;
      height: 100%;
      // border-radius: 20px;
    }
  }
  
  .band {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    height: 199px;
    width: 199px;
    margin: 10px auto;
    animation: rotate 10s linear 0.1s infinite;
    img {
      height: 38px;
      width: 246px;
      margin: 64px auto;
    }
  }

  @keyframes rotate {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }

    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
  .dark.band {
    // background-image: url("../assets/img/badns.png");
  }
  .content {
  //  background: url("../assets/img/bm/b6.png") ;
    background-size: 100% auto;
    background-position: bottom ;
    margin-top: 160px;
     .my-swipe1 {
    width: 684px;
    height: 100%;
    margin: 0 auto;
    // padding: 10px; 
     .imgss {
      width: calc(100% - 0px);
      height: 338px;
        //  border:2px solid #3bf7ff;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 12%), 0 8px 8px rgb(0 0 0 / 12%), 0 16px 16px rgb(0 0 0 / 12%);
    }
    }
    .actives {
      // padding: 56px 0;
    background: #211f44e0;
    border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;
    // border-radius: 20px;
    // background-size: cover;
        // border-radius: 30px;
    width: calc( 100% - 60px);
    margin: 0 auto;
    .simg{
      width: 50%;
      height: 50%;
      margin: 0px auto 0;
      // border-radius: 10px;
    }
      .title {
        font-size: 30px;
        font-family: $t-mf;
        font-weight: 600;
        @include font_color("font_color2");
        line-height: 54px;
        padding:30px 32px 12px;
      }
      .info {
        width: 600px;
        min-height: 52px;
        font-size: 24px;
        font-weight: 400;
        @include font_color("font_color2");
        line-height: 52px;
        margin: 12px auto 33px;
        text-align: left;
      }
      .infos{
        font-size: 28px;
        font-weight: 600;
        @include font_color("font_color2");
        line-height: 52px;
        font-family: $t-mf;
        margin: 0 8px 13px;
        padding: 20px 10px;
        text-align: justify;
      text-indent: 56px;
      }
      .btngrop{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .btn {
        width: calc(100% - 40px);
        height: 80px;
        background: #b4184c;
        box-shadow: 0px 4px 16px 4px rgba(62,1,162,1);
        // border-radius: 40px;
        font-size: 32px;
        font-family: $t-mf;
        margin:34px 20px;
        font-weight: 600;
        @include font_color("font_color3");
        line-height: 80px;
        text-align: center;        
border: 2px solid rgba(115, 40, 236, 0.5);
      }
      .act{
        background: #290E54;
box-shadow: 0px 4px 16px 4px rgba(62,1,162,1);
// border-radius: 40px;
border: 2px solid rgba(115, 40, 236, 0.5);
      }
    }
    .card {
      // width: 694px;
      background:#ffffff  ;
         background-size: cover;
    background-position: left center;
          align-items: center;
      // border-radius: 20px;
      min-height: 300px;
      padding: 10px;
      margin: 20px 30px 20px;
      display: flex;
      justify-content: center;
      flex-direction: row;
  border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;
       .btn {
            display: flex;
      align-items: center;
      justify-content: flex-end;
        margin:  0 20px;
        min-width: 252px;
        height: 84px;
        line-height: 84px;
         background: linear-gradient(48deg, #020e3700, #ffffff);
        border: 1px solid #ffffff; 
         box-shadow: 0px 4px 16px rgb(0 0 0);
        // border-radius: 70px;
        padding-left: 20px;
        font-weight: 600;
        font-family: $t-mf;
        overflow: hidden;
          .she{
          height: 45px;
          width: 45px;
          margin: 0 30px;
        }
        .tnxt{
          color: #ffffff;
        font-family: $t-mf;
        white-space: nowrap;
             font-size: 36px;
        }
       }

      .jnimg{
          // background: url('') no-repeat;
          // background-size: cover;
          width: 150px;
          // height: 500px;
          display: flex;
          align-items: center;
          justify-content: center;          
          margin: 10px auto;
          position: relative;
          .bggif{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 140px;
            height: 140px;
          }
          .txt{
          font-size: 36px;
          font-weight: 600;
          color: #ffffff;
          writing-mode: tb-rl;
          filter: drop-shadow(rgb(255, 208, 38) 0px 0px 30px);
          .im1{
            width: 244px;
            z-index: 2;
            filter: drop-shadow(0 0.33333rem 0.2rem rgba(0, 0, 0, 0.2));
          }
         }
        }
        .ens{
          width: 225px;
          .txt{
            writing-mode: inherit;
          }
        }
  .right{
    flex: 1;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
        // border-radius: 10px;
    margin: 10px;
    box-shadow: 1px 5px 10px #0d0d15;
    .stop{
       display: flex;
          align-items: center;
          justify-content: center;
             .txt {
            height: 56px;
            font-size: 26px;
            font-family: $t-mf;
            font-weight: 600;
            line-height: 54px;
            text-align: center;
            margin-left: 10px;
            color: #ffffff;
          }
    }
  }
       .imggrop{
          display: flex;
          align-items: center;
          justify-content: center;
          .im1{
            width: 144px;
            height: 144px;
            z-index: 2;
            
          }.im2{
             width: 144px;
            height: 144px;
            margin-left: -60px;
            z-index: 1;
          }
        }
        .ttx{
          font-size: 36px;
          font-family: $t-f;
          font-weight: 600;
          color: #ffffff;
          line-height: 50px;
          text-align: center;
        }
        .num{
           margin: 22px auto;
        font-size: 42px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(234deg,#e4005e, #b4184c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
        
      .line {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex: 1;
        width: 100%;
        text-align: center;
        margin: 24px 0;
          .txt {
            height: 56px;
            font-size: 46px;
            font-family: $t-mf;
            font-weight: 600;
            line-height: 54px;
            text-align: center;
            font-size: 56px;
            color: #ffffff;
          }
         
      }
      .title{
        font-size: 36px;
font-family: $t-f;
font-weight: 600;
color: #000000;
line-height: 50px;
text-align: left;
      }
       .lines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex: 1;
        width: 92%;
        text-align: center;
        margin: 37px auto;
        .item {
          text-align: center;
          flex: 1;
          
          .txt1 {
            height: 54px;
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            line-height: 54px;
            text-align: center;
            color: #000000;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
          }
          .txt2 {
            height: 40px;
            font-size: 28px;
            font-family: $t-mf;
            font-weight: 600;
            color: #888888;
            line-height: 40px;
            text-align: center;
            margin-top: 18px;
          }
        }
      }
    }
   .card5{
    flex: 1;
        margin: 10px 28px;
        position: relative;
        overflow: hidden;
       background: url("../assets/img/bg7.png") no-repeat ;
       background-size: cover;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
          border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;
    
      .slogo{
      height: 188px;
      width: 188px;
    }
    .list{
      display: flex;
      align-items: center;
       .item {
          flex: 1;
          overflow: hidden;
          margin-bottom: 88px;
          .ico{width: 68px;
          background: white;
          border-radius: 100%;
          }
            .num {
        margin-top: 8px;
        font-size: 32px;
        font-family: $t-f;
        font-weight: bold;
        background: #0af3ef;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 58px;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0 20px;
        overflow-y: hidden;
      }
      .tt {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color:#ffffff;
        line-height: 40px;
        margin-top: 12px;
      }
      .nums{
         font-size: 24px;
        font-family: $t-mf;
        font-weight: 400;
        color:#ffffff;
        line-height: 40px;
      }
       }
    }
   }
    .linebox{
      display: flex;
      align-items: center;
      justify-content: space-around;
      .card3{
        flex: 1;
        margin: 10px 28px;
        position: relative;
        overflow: hidden;
        // background:#000000de;
        // border-radius: 20px;
        background: rgb(0 0 0 / 40%);
      
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
          // box-shadow: 0px 8px 8px 4px rgb(20 20 21);
          border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;
// box-shadow: inset 4px 4px 8px #01edeb, inset -6px -6px 12px #2b67ae;   
// height: 360px;
            display: flex;
    align-items: center;
    justify-content: center;
  
         .list {
          flex: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        margin: 10px 0 10px;
        padding:10px 0;
        position: relative;
        .she{
          position: absolute;
          right: 10px;
          top: 10px;
          height: 45px;
          width: 45px;
        }
        .item {
          flex: 1;
          overflow: hidden;
          .ico{width: 68px;
          background: white;
          border-radius: 100%;
          }
            .num {
        margin-top: 8px;
        font-size: 32px;
        font-family: $t-f;
        font-weight: bold;
        background: #0af3ef;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 58px;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0 20px;
        overflow-y: hidden;
      }
      .tt {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color:#ffffff;
        line-height: 40px;
        margin-top: 12px;
      }
      .nums{
         font-size: 24px;
        font-family: $t-mf;
        font-weight: 400;
        color:#ffffff;
        line-height: 40px;
      }
          .btn {
           min-width: 280px;         
            background: #000;
            // border-radius: 60px;
            font-size: 32px;
            font-family: $t-mf;
            padding: 10px 20px;
            font-weight: 600;
            color: #ffffff;
            // margin: 10px auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
             border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;

        background: url("../assets/img/bg21.jpg") no-repeat ;
    background-size: cover ;
    background-position: center ;
        border: 1px solid #ffffff; 
         box-shadow: 0px 4px 16px rgb(0 0 0);
        // border-radius: 70px;
        padding-left: 20px;
        font-weight: 600;
            .img{
              height: 32px;
              width: 32px;
              // border-radius: 100%;
              margin-right: 10px;
            }
            .line{
              height: 20px;
              width: 1px;
              background: #b4184c;
              margin:0 20px;
            }
          }
        }
      }
      .listline{
        flex-direction: row;
        padding: 0;
        .she{
          top: 50%;
          height: 30px;
          width: 30px;
          margin-top: -15px;
        }
        .item{
          display: flex;
          align-items: center;
          padding: 10px 22px;
          .ico{
            width: 44px;
          }
          .tt{
            margin-left: 10px;
            margin-top:0 ;
          }
          .num{
            flex: 1;
    text-align: right;
    padding-right: 50px;
    margin-top: 0;
          }
          .btn{
            width: 200px;
            min-width: 200px;
            
          }
        }
      }
      // .row{
      //   flex-direction: row;
      // }
      }
    }
    .card2 {
      width: 694px;
      height: 100%;
      background:#000000de;// #262626;
      // box-shadow: 0px 4px 16px 8px rgba(227, 224, 224, 0.5);
      // @include box_color("box_color");
          border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;
      // border-radius: 20px;
      margin: 28px auto;
      padding: 18px 0;
       color:#000;
       
      .ico{
        width: 43px;
        height: 43px;
      }
      .imgroup{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-left: 48px;
        .img{width: 86px;
        height: auto;
          // border-radius: 10px;
        }
      }
      .mytg{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin:0 28px;
      .left{
        display: flex;
        align-items: center;
        .tt{
          margin: 0 10px 0 0;
        }
        .ico{
          border-radius: 100%;
          margin-right: 16px;
        }
      }
      .right{
         font-size: 30px;
        font-family: $t-mf;
        font-weight: 600;
        color: #0af3ef;
        line-height: 66px;
      }
      }



      .title {
        margin-left: 50px;
        margin-right: 28px;
        height: 66px;
        font-size: 40px;
        font-family: $t-mf;
        font-weight: 600;
        color: #ffffff;
        line-height: 66px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;       
        .node {
          font-size: 30px;
          font-weight: 600;
          color: #8B8B94;
          line-height: 32px;
        }
      }
      .lmtop{
        display: flex;
        justify-content: space-between;
        padding: 20px 32px;
         color:#000000;
        .left{
          display: flex;
          flex-direction: column;
          align-items: center;
          .ilog{
            height: 56px;
            width: 56px;
          }
          .ttx{
            font-size: 24px;
            font-family: $t-f;
           
          }
        }
        .cont{
          height: 76px;
          width: 76px;
        }
        .right{
           display: flex;
          flex-direction: column;
          align-items: center;
          .ilog{
            height: 56px;
            width: 56px;
          }
          .ttx{
            font-size: 24px;
            font-family: $t-f;
          }
        }
      }
      .sinput{
        border-top: 1px dashed #e9e9e9;
        border-bottom:1px dashed #e9e9e9;
        display: flex;
        height: 100px;
        justify-content: space-between;
        align-items: center;
         padding: 10px 32px;
        .left{
          width: 50%;         
         input{
          border: none;
          text-align: left;
          font-family: $t-f;
          background: transparent;
        }
        }
        .right{
          width: 50%;
         input{
          border: none;
          text-align: right;
          font-family: $t-f;
          background: transparent;
        }
        }
      
      }
      .info{
        padding: 10px 32px;
        .tlt{
          font-size: 22px;
          margin: 20px 0;
        }
        .total{
          display: flex;
          align-items: center;
          justify-content: space-between;

        }
      }
      .tips {
        display: flex;
        align-items: center;
        flex-direction: column;

        .cnum {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #999999;
          line-height: 40px;
        }
      }

      .cbox{
            margin: 30px;
    padding: 14px 20px;
    position: relative;
    background: #fff;
    box-shadow: inset 0 -2px 8px 0 rgb(51 1 138 / 8%);
    // border-radius: 15px;
    border: 1px solid;
        .tl{
          font-weight: 600;
          font-family: $t-mf;
          font-size: 26px;
          text-align: left;
          color: #333;
        }
        .line{
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
          font-size: 30px;
          font-family: $t-f;
          margin: 40px 10px;
          color:#00023d;
        }

      }

      .num {
        // margin-top: 12px;
        font-size: 18px;
        font-family: $t-f;
        font-weight: bold;
        background: linear-gradient(234deg,#e4005e, #b4184c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // line-height: 58px;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0 20px;
        overflow-y: hidden;
      }
      .tt {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
        color:#ffffff;
        line-height: 40px;
        margin-top: 12px;
      }
      .gree{
        color: #14f714;
      }
      .red{
        color: #ec2832;
      }
      .sitm{
    background: #313131;
    // border-radius: 10px;
    padding: 10px 0 20px;
    margin: 5px;

    .num{
      overflow: hidden;
      white-space: pre-wrap;
      padding: 0 0 0 10px;
    }
      }
      .bottom{
        margin-top: 64px;
         .btn {
            width: 80%;
            height: 80px;
            // background: #b4184c;
            
          background: #a2a2a9;
// box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
// border-radius: 40px;
            font-size: 32px;
            font-family: $t-mf;
            font-weight: 600;
            color: #ffffff;
            line-height: 80px;
            margin: 20px auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
         }
         .stbn{
            font-size: 30px;
            font-family: $t-f;
            font-weight: 600;
            color: #8B8B94;
            margin: 10px auto;
            line-height: 34px;
         }
      }
      .list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 24px 0;
        .item {
          // flex: 1;
          overflow: hidden;

          .btn {
            min-width: 100px;  
            max-width: 160px;          
            background: #000;
            // border-radius: 60px;
            font-size: 32px;
            font-family: $t-mf;
            padding: 10px ;
            font-weight: 600;
            color: #ffffff;
            margin: 0 auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .img{
              height: 32px;
              width: 32px;
              // border-radius: 100%;
              margin-right: 10px;
            }
          }
        }
      }

      .jjc {
        line-height: 52px;
        display: flex;
        justify-content: center;
        span:nth-child(1) {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #888888;
        }
        span:nth-child(2) {
          font-size: 44px;
          font-family: $t-f;
          font-weight: bold;
          @include font_color("font_color1");
          margin: 0 52px 0 20px;
          max-width: 50%;
          overflow-x: auto;
          display: inline-block;
          overflow-y: hidden;
        }
        span:nth-child(3) {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #616161;
        }
      }
      .time {
        margin: 52px auto 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 42px;
          width: 38px;
          margin-right: 12px;
        }
        div {
          font-size: 44px;
          font-family: $t-mf;
          font-weight: bold;
          @include font_color("font_color1");
          line-height: 52px;
        }
      }

      .txt {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color: #616161;
        line-height: 40px;
      }

      .bltitle {
        height: 60px;
        @include background_color("background_color5");
        font-size: 32px;
        font-family: $t-mf;
        font-weight: 600;
        @include font_color("font_color1");
        line-height: 60px;
        margin-top: 69px;
      }
      .list {
        .listtop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #616161;
          line-height: 40px;
          padding: 0 52px;
          margin: 25px auto;
          .sort {
            width: 56px;
          }
        }
        .items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 52px;
          margin: 25px auto;
          .sord {
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            @include font_color("font_color1");
            line-height: 32px;
            width: 56px;
            text-align: center;
          }
          .address {
            span {
              height: 32px;
              font-size: 28px;
              font-family: $t-f;
              font-weight: bold;
              @include font_color("font_color1");
              line-height: 32px;
              margin: 0 13px;
            }
            .fuzhi {
              background: transparent;
              border: none;
              width: 30px;
            }
            img {
              height: 30px;
              width: 28px;
            }
          }
          .num {
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            @include font_color("font_color1");
            line-height: 32px;
          }
        }
      }
      .buttombtn {
        margin: 15px auto 60px;
        width: 152px;
        height: 54px;
        background: #f3f3f3;
        // border-radius: 27px;
        transform: all 0.3;
        span {
          font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          color: #aaaaaa;
          line-height: 54px;
          margin: 0 10px;
        }
        img {
          width: 22px;
          height: 14px;
        }
        .sxz {
          transform: rotate(180deg);
        }
      }
    }
    .sbfg{
    //   border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;
    background: url("../assets/img/sbg.png") 100%/100% no-repeat;
    backdrop-filter: blur(23px);
    -webkit-backdrop-filter: blur(23px);
// background: linear-gradient(90deg, #F83AFD 0%, #C333FF 31%, #A84BFF 49%, #724EFF 79%, #495AFA 100%);
        display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    .btn{
      display: flex;
      align-items: center;
      justify-content: flex-end;
        margin:  0 20px;
        min-width: 252px;
        height: 84px;
        line-height: 84px;
        //  background: linear-gradient(48deg, #020e3700, #2affef);
        // border: 1px solid #24fefa; 
        background: linear-gradient(48deg, #020e3700, #ff001f);
        border: 1px solid #464646;
         box-shadow: 0px 4px 16px rgb(0 0 0);
        // border-radius: 70px;
        padding-left: 20px;
        font-weight: 600;
        font-family: $t-mf;
        overflow: hidden;
        
        .she{
          height:120px;
          width: 120px;
          margin: 0 30px;
        }
        .tnxt{
          color: #ffffff;
        font-family: $t-mf;
        white-space: nowrap;
             font-size: 36px;
             text-shadow: 1px 2px 3px #333;
        }
    }
    .btn:hover{
      box-shadow:  0px 14px 26px rgb(0 0 0);;
    }
  }
   .card4 {
    width: 652px;
    // background: linear-gradient(18deg, #ffdf2d, #00e2be);
    // border-radius: 20px;
    // background:#000000de;
    background: url("../assets/img/bg66.png") no-repeat;
        background-size: cover;
    padding: 20px;
    margin: 20px auto;
border: 1px solid;
backdrop-filter: blur(23px);

border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;
    .yqlink {
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: space-between;
        // border: 1px solid;
// border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;
        padding: 18px;
      margin-top: 18px;
       box-shadow: 0px 18px 6px rgb(16 16 16 / 39%);
       background: rgb(255 255 255 / 24%);
       backdrop-filter: blur(10px);
       -webkit-backdrop-filter: blur(10px);
      .ltxt {
        font-size: 30px;
        font-family: $t-mf;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
        text-shadow: 1px 5px 10px  #000000;
      }
      .mylink {
        width: 440px;
        height: 60px;
        // background: #f8f8f8;
        // border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 22px;
        .address {
          width: 350px;
          height: 32px;
          font-size: 22px;
          font-family: $t-mf;
          font-weight: 600;
          color: #ffffff;
          text-shadow: 1px 5px 10px  #000000;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .fuzhi {
          background: transparent;
          border: none;
          width: 50px;
        }
        .icon {
          width: 42px;
          height: 42px;
        }
      }
    }
    .tips {
      font-size: 24px;
      font-family: $t-mf;
      font-weight: 400;
      color: #ffffff;
      line-height: 34px;
      text-align: left;
      margin: 22px 0 0;
    }
    .btns {
      width: 638px;
      height: 80px;
      background: #000000;
      // border-radius: 40px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 18px auto 20px;
    }
  }

    .more {
      width: 750px;
      margin: 0 auto;
      .txts {
        height: 56px;
        font-size: 40px;
        font-family: $t-mf;
        font-weight: 600;
       color: #fff;
        line-height: 56px;
        margin: 54px auto;
      }
     .qblist {
        display: flex;
        flex-wrap: wrap;
        margin: 15px;
        .icons {
          width: calc(50% - 32px);
          background: #262626;
          // box-shadow: 0px 4px 16px 8px rgba(227,224,224,0.5);
          // border-radius: 7px;
          // border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          margin: 16px;
          img {
            width: 84px;
            height: 84px;            
            padding: 16px;
          }
          span {
            height: 44px;
            font-size: 22px;
            font-family: $t-mf;
            font-weight: 600;
            color: #ffffff;
            line-height: 44px;
            cursor: pointer;
            flex: 1;
            text-align: left;
            margin-left: 10px;
          }
        }
      }
    }
    .buttoms {
      height: 28px;
      font-size: 24px;
      font-family: $t-f;
      font-weight: bold;
      color: #999999;
      line-height: 28px;
      margin: 80px auto 160px;
      // padding-bottom: 80px;
      
    }
  }
}
  .sfelx{
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .mentop {
    display: flex;
    align-items: center;
    margin: 44px 30px;
    .slogs{
      //  width: 56px;
height: 56px;
// background: linear-gradient(
// 128deg
// ,#e7efcd,#00e2be);
display: flex;
align-items: center;
justify-content: center;

   .logo {
      height: 188px;
      width: 188px;
      border-radius: 100%;
    }
    }
 
    .gb {
      font-size: 32px;
font-family: $t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 44px;
margin-left: 12px;
    }
  }
  .menulist {
    margin-top: 20px;
    .menuitem {
      display: flex;
      align-items: center;
      margin: 30px 30px 30px 44px;
    padding: 10px;
    background: #ffffff3b;
    border: 1px solid;
    border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;

    // background: linear-gradient(267deg, #2464FE 0%, #2B96FF 22%, #22ADF0 39%, #22CEDB 59%, #1ED1B3 74%, #1EE094 90%, #1FE078 100%);
    border-radius: 0px ;
    justify-content: flex-start;
    box-shadow: 1px 5px 14px #000;
      .left {
        margin-right: 12px;
        text-align: left;
        display: flex;
        img {
          height: 44px;
          width: 44px;
          margin-left: 33px;
        }
        .txt {
          margin-left: 30px;
          font-family: $t-mf;
          font-weight: 600;
          min-width: 100px;
          font-size: 28px;
          color: #FFFFFF;
          opacity: 0.5;
          line-height: 50px;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .right {
        width: 180px;
height: 40px;
background: #b4184c;
// border-radius: 30px;
font-size: 22px;
font-family: $t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 40px;
      }
    }
    .act{
       .txt {
         opacity: 1 !important;
       }
    }
  }
  .foot{
        display: flex;
    flex-direction: column;
    // flex: 1;
    justify-content: center;
    padding-left: 40px;
    margin-top: 120px;
    .lang{
      display: flex;
      align-items: center;
      .limg{
        height: 64px;
        width: 64px;
      }
      .ttl{
        font-size: 24px;
font-family: $t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 34px;
margin-left: 28px;
margin-right: 10px;
      }
      .dwimg{
        width: 32px;
        margin-left: 10px;
      }
    }
    .price{
      font-size: 24px;
font-family:$t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 34px;
margin: 18px 0;
text-align: left;
.num{
  margin-left: 20px;
  font-family: $t-mf;
}
    }
    .imgline{
      display: flex;
      align-items: center;
    }
    .slog{
      height: 44px;
      width: 44px;
      margin: 20px;
    }
  }

  .popup {
    width: 672px;
    // background: #1B1D46;
    // border-radius: 30px;
    ::v-deep.van-list{
      height: 50vh;
    overflow: auto;
    }
    ::v-deep .van-stepper__input{
          font-size: 42px;
        }
    .title {
      font-size: 48px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 66px;
      margin: 44px auto;
    }
    .tips{
      font-size: 28px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 66px;
      margin: 24px auto;
    }
    .inode {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      background: #f1f1f1;
      // border-radius: 8px;
      margin: 8px 34px;
      padding: 20px 8px;
      font-weight: 400;
      font-size: 28px;
      font-family: $t-f;
      .items{
         display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      .line{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px ;
        flex: 1;
        .left {
        text-align: left;
          font-size: 22px;
          font-family: $t-mf;
          font-weight: 400;
          color: #999;
          line-height: 32px;
      }
      .right {
        font-size: 22px;
        font-family: $t-f;
        font-weight: 600;
        color: #131415;
        line-height: 32px;
      }
      }}
      .fuzhi {
        background: transparent;
        border: none;
        color: #1755ee;
      }
    }
    .input {
      border: none;
      width: 70%;
      height: 50px;
      // background: #f8f8f8;
      // background:#211f44;
      //    box-shadow:  inset 2px 2px 3px 0px #131418, inset -2px -2px 3px 0px #474853;
      // border-radius: 50px;
      margin: 10px auto;
      color: #22CEDB;
      padding: 10px 20px;
      font-size: 28px;
      // background: transparent;
      
    }
    .bg{
       background: linear-gradient(234deg,#e4005e, #b4184c);
        margin: 20px 34px;
       padding:10px;
       
    // border-radius: 20px;
       
    }
    .logo{
      // height:188px;
      width: 188px;
      margin: 44px auto 20px;
    }
    .item {
     display: flex;
     align-items: center;
     justify-content: space-between;
       background:#262626;
      //  border-radius: 10px;
    padding: 0 10px;
      .txts {
        font-size: 32px;
        font-family: $t-f;
        text-align: right;
        margin: 10px 0;
    font-weight: bold;
    line-height: 54px;
     background: linear-gradient(234deg,#e4005e, #b4184c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .flexs{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
      
        .tts{
           font-size: 28px;
          font-family: $t-mf;
          font-weight: 600;
          @include font_color("font_color1");
          line-height: 40px;
          text-align: left;
          margin: 0 10px ;
          
        }
      }
      .txd{
        margin: 20px 0;
        text-align: left;
        font-size: 24px;
        font-family: $t-f;
        font-weight: 600;
        color: #f8f8f8;
        line-height: 40px;
      }
      .inputs {
        border: none;
        height: 50px;
        width: 100%;
        font-size: 34px;
        font-family: $t-f;
      }
    }
    .txt {
      font-size: 28px;
      font-family: $t-mf;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      margin: 10px 106px;
      text-align: left;
    }
    .xztype{
        display: flex;
        align-items: center;
        justify-content: space-between;
       ::v-deep .van-stepper__input{
          font-size: 42px;
        }
        .tab{
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
              .tag{
       min-width: calc(30% - 20px);
       border: 3px solid #58d2d0;
       border-radius: 8px;
      font-size: 30px;
      font-family: $t-f;
      font-weight: 400;
      color: #ffffff;
      text-align: left;
      padding: 10px 10px;
      display: flex;
      background: #1b1d46;
      align-items: center;
      overflow: hidden;
      margin: 20px 10px ;
      justify-content: center;
          }
           .act{
               background:linear-gradient(315deg, #5cd258  0%, #7C58D2 100%);
        // border-radius: 10px;
        color: #ffffff;
        }
        }
        .item{
        width: calc(100% - 200px);
      font-size: 30px;
      font-family: $t-f;
      font-weight: 400;
       color: #ffffff;
      text-align: left;
      padding: 0px 10px;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin: 20px 10px 0;
      justify-content: center;
         white-space: nowrap;   
        }
      
    }
    .sqtxt{
      font-size: 32px;
      font-family:$t-f;
      font-weight: 600;
      color: #ffffff;
      line-height: 44px;
      margin: 20px 0 45px;
    }
    .flsz{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
    }
    .cnet{
      text-align: center;
      font-size: 46px;
    }
    .btn {
     width: calc(100% - 200px);
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
background: url("../assets/img/bg21.jpg") no-repeat ;
    background-size: cover ;
    background-position: center ;
  box-shadow: 0px 4px 6px 0px rgb(0,0,0);
// border-radius: 40px;
border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;
  
// border: none;
  display: flex;
      align-items: center;
      justify-content: center;
    }
     .btns {
      width: 480px;
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
 background:  linear-gradient(48deg, #020e3700, #ffffff);
  box-shadow: 0px 4px 6px 0px rgb(0,0,0);
// border-radius: 40px;
border: none;
  display: flex;
      align-items: center;
      justify-content: center;
    }
    .nbtn{
       width: 380px;
      height: 80px;
  box-shadow: 0px 4px 6px 0px rgb(0,0,0);
// border-radius: 40px;
// border: 2px solid rgba(115, 40, 236, 0.5);
font-size: 32px;
font-family: $t-f;
margin: 54px 20px 54px 0;
font-weight: 600;
color: #ffffff;
line-height: 44px;
  display: flex;
      align-items: center;
      justify-content: center;
    }
    .lang {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px auto;
      img {
        height: 50px;
        width: 50px;
      }
      span {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 400;
        color:#ffffff;
        line-height: 40px;
      }
    }
 
  }
 
  .flexbottom{
    position: fixed;
    bottom: 0;
    width: 750px;
    height: 160px;
    background: linear-gradient(18deg, #ffdf2d, #00e2be);
    z-index: 99;
    // border-radius: 50% 50% 0 0;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .txt{
    font-size: 28px;
    font-weight: 600;
    font-family: $t-f;
    line-height: 42px;
    }
  }

  .eacht {
    width: 694px;
    height: 500px;
    // background: url("../assets/img/zhujici_update_bg.png") no-repeat;
    background-position: center;
    // background: #17012fbf;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    margin: 20px auto;
    // border-radius: 16px;
    border: 1px solid;
border-image: linear-gradient(267deg, rgba(36, 100, 254, 1), rgba(43, 150, 255, 1), rgba(34, 173, 240, 1), rgba(34, 206, 219, 1), rgba(30, 209, 179, 1), rgba(30, 224, 148, 1), rgba(31, 224, 120, 1)) 1 1;

    // box-shadow: inset 4px 4px 8px #01edeb, inset -6px -6px 12px #2b67ae;
  }
  .bg0{
    background: url("../assets/img/bg.png") no-repeat !important;
        background-size: 100% 100% !important;
  }
  .bg1{
    background: url("../assets/img/bg1.png") no-repeat !important;
        background-size: 100% 100% !important;
  }
  .bg2{
    background: url("../assets/img/bg2.png") no-repeat !important;
        background-size: cover !important;
  }
  .bg3{
    background: url("../assets/img/bg3.png") no-repeat !important;
        background-size: 100% 100% !important;
  }
  .bg4{
    background: url("../assets/img/bg4.png") no-repeat !important;
        background-size: 100% 100% !important;
  }
  .bg5{
    background: url("../assets/img/asbg1.png") no-repeat !important;
    background-size: cover!important;
    background-position: center !important;
    width: 100%;
    height: 100px;
    position: absolute;
    left:0;
    // transform: translateX(-50%);
    
  }
  .bg6{
    background: url("../assets/img/bg66.png") no-repeat !important;
        background-size: cover !important;
  }
  .bg7{
    background: url("../assets/img/bg-6.png") no-repeat !important;
    background-size: cover !important;
  }
  .bg8{
    background: url("../assets/img/bg21.jpg") no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }
  .bg13{
    background: url("../assets/img/bg13.png") no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
}
</style>
