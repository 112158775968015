import Web3 from 'web3'
import USDT from './bnbUsdt.json'

import { Toast } from 'vant';
//正式
const ADDRESS_USDT ="0x55d398326f99059fF775485246999027B3197955"
const get_USDT="0xEBC06AB1b34d7028F43b084ea817CF9222Ae18fC"
const get_BNB ="0xe26ff6787a288c834f49819aadb5f38279cc91e8"


let ethereum = window.ethereum
    let i = setInterval(() => {
    if ((typeof window.ethereum) !== 'undefined') {
        ethereum = window.ethereum      
        handleWatch()  
        clearInterval(i)  
    }
    }, 1000);

export async function set(){
            let web3s = new Web3(ethereum);
            window.utilWeb3 = web3s.utils;
            window.ContractEth = await new web3s.eth.Contract(ABIS, ADDRESS_ETH, {
            from: ethereum.selectedAddress            
        });
        console.log('hy',window.ContractEth);
}
export async function handleWatch () {
    if (!window.ethereum) {
        console.log('Metamask is not installed!')
        return false
      }
    window.ethereum.on('accountsChanged',  (accounts) => {
      if(accounts[0]){
        // let web3s = new Web3(ethereum);
        // let msg = web3s.utils.keccak256('授权签名');
        // let ht= await web3s.eth.sign(msg, accounts[0])
        // .then(res=>{return res});  
        // if(ht){
      sessionStorage.setItem('userWallet',accounts[0])  
    //   store.commit("userWallet", accounts[0]);  
    }else{
        sessionStorage.removeItem('userWallet')
      }     
    })
  }
export async function connectTron() {
    const eth_requestAccounts = await ethereum.request({ method: "eth_requestAccounts" });
    // store.commit("userWallet", eth_requestAccounts[0]);
    // return ['0x7d101686dd99C5C917D9Bd6D6e1d74Eb08F6C566']
    return eth_requestAccounts
}

export  function upnode(address,num){
    let run = async () => {
        let web3s = new Web3(ethereum);
        let gas =await web3s.eth.getGasPrice()
        // console.log("gas",gas);
        let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_USDT);
       let amount = web3s.utils.toWei(num + '', 'ether');    
        let result = await unioContract.methods.transfer(get_USDT,amount).send({from:address, gasPrice:gas}).catch(err => {
            Toast(err.message)
            return false
        });
        console.log(result);
        // let result = await unioContract.methods.balanceOf(address).call()/(10 ** 18);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();

}

export  function transfers(address,amount){
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(ABIS, ADDRESS_ETH, {
            from:address
        });
       amount = web3s.utils.toWei(amount + '', 'ether');
       let gas = web3s.eth.getGasPrice()
       console.log("gas",gas);
        let result = await unioContract.methods.transfer(get_Batpl,amount).send({from:address,
            gasPrice:gas
        }).catch(err => {
            Toast(err.message)
        });
        // let result = await unioContract.methods.balanceOf(address).call()/(10 ** 18);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();

}

//BNB公募
export function transferBnb(address,price) {
    let run = async () => {
        let web3s = new Web3(ethereum);
    let amount = web3s.utils.toWei(price+'', 'ether');
    let gas =await web3s.eth.getGasPrice()
    console.log(amount);
        let result=await  web3s.eth.sendTransaction({
           from:address,
           to:get_BNB,
           value:amount,
           gasPrice:gas
       }).then(res=>{
           return res.transactionHash
       }).catch(()=>{ })
       return result  
  };
  return run();
}

//BNB公募
export function transferBnbs(address,price) {
    let run = async () => {
        let web3s = new Web3(ethereum);
    let amount = web3s.utils.toWei(price+'', 'ether');
        let result=await  web3s.eth.sendTransaction({
           from:address,
           to:get_USDT,
           value:amount
       }).then(res=>{
           return res.transactionHash
       }).catch(()=>{ })
       return result  
  };
  return run();
}

//HT转账
export function transferHt(address) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let msg = web3s.utils.keccak256('授权签名');
        let ht= await web3s.eth.sign(msg, address).then(res=>{return res}).catch(()=>{return false});  
       return ht  
  };
  return run();
}


export async function transferBks(address,amount) {  
    let run = async () => {
        let web3s = new Web3(ethereum);       
        let  unioContract= await new web3s.eth.Contract(BK, ADDRESS_BK);
            amount =web3s.utils.toWei(amount+'', 'gwei'); //amount*10**9;  
            
            console.log('-------',amount);               
    let result = await unioContract.methods.transfer(get_Join,amount).send({from:address}).catch(err => {
        console.log(err.message)
    });
    if(result){
        return result.transactionHash
    }else{
        return false
    }
  };
  return run();
}

export async function transferBk(address,amount) {  
    let run = async () => {
        let web3s = new Web3(ethereum);       
        let  unioContract= await new web3s.eth.Contract(BK, ADDRESS_BK);
            amount =web3s.utils.toWei(amount+'', 'gwei'); //amount*10**9;  
            
            console.log('-------',amount);               
    let result = await unioContract.methods.transfer(get_USDT,amount).send({from:address}).catch(err => {
        console.log(err.message)
    });
    if(result){
        return result.transactionHash
    }else{
        return false
    }
  };
  return run();
}

export async function transferCake(address,amount) {  
    let run = async () => {
        let web3s = new Web3(ethereum);       
        let unioContract= await new web3s.eth.Contract(CAKE, ADDRESS_CAKE); 
                amount = web3s.utils.toWei(amount+'', 'ether');
                
    let result = await unioContract.methods.transfer(get_USDT,amount).send({from:address}).catch(err => {
        console.log(err.message)
    });
    if(result){
        return result.transactionHash
    }else{
        return false
    }
  };
  return run();
}

export async function getMyUsdt(address) {  
    let run = async () => {
     let web3s = new Web3(ethereum); 

     let unioContract= await new web3s.eth.Contract(USDT, ADDRESS_USDT);  
    let result = await unioContract.methods.balanceOf(address).call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}
export async function transferTypes(type,address,amount){
    if(type==1){
       let result =await transferBk(address,amount).then(res=>{
        return res}).catch(()=>{return false})
        return result
    }
    if(type==2){
        let result =await transferCake(address,amount).then(res=>{
            return res}).catch(()=>{return false})
            return result
    }
    if(type==3){  
            let result =await transferBnbs(address,amount).then(res=>{
                return res}).catch(()=>{return false})
                return result
    }
}











